import React from 'react'
import './experience.css'
import {AiFillCheckCircle} from 'react-icons/ai'

const Experience = () => {
  return (
    <section id='experience' className='experience_w'>
      <h2 >My Skills</h2>

      <div className="container experience__container">
        <div className="experience__backend">
            <h3><b>Programming skills</b> </h3>
            <div className="experience__content">
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Spring Boot</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Java</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Python</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>SQL</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Apache Kafka</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Maven</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
            </div>
        </div>
        <div className="experience__backend">
            <h3><b>Project Management and Methodologies</b> </h3>
            <div className="experience__content">
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Microsoft Teams</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Visio</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Confluence</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>SharePoint</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Microsoft Planner</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Open API Documentation</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
            </div>
        </div>
      </div>
      <div className="container experience__container">
        <div className="experience__backend">
            <h3><b>Database</b></h3>
            <div className="experience__content">
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>MySQL</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>AWS Redshift</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Azure SQL DB</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>MongoDB</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Postgres</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
            </div>
        </div>
        <div className="experience__backend">
            <h3><b>Data Visualisation Tools</b></h3>
            <div className="experience__content">
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Tableau</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Power BI</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>SSIS</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Azure Data Factory</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Azure Databricks</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
            </div>
        </div>
      </div>
      <div className="container experience__container">
        <div className="experience__backend">
            <h3><b>Quality Assurance</b></h3>
            <div className="experience__content">
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Selenium</h5>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>JUnit</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>API Testing</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
            </div>
        </div>
        <div className="experience__backend">
            <h3><b>Tools</b></h3>
            <div className="experience__content">
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Git</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Postman</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Jira</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>VS Code</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Intelli J IDEA</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'> 
                <AiFillCheckCircle className='experience__details-icon'/>
                <div>
                <h5>Service Now</h5>
                <small className='text-light'>Experienced</small>
                </div>
              </article>
            </div>
        </div>
      </div>

    </section>
  )
}

export default Experience